import { CatmullRomCurve3,InstancedMesh, Object3D } from "three" 
/**

* @param { CatmullRomCurve3 } instance_path
* @param { InstancedMesh } instance
* @param { number } instance_n
* @param { number } carspeed

**/

export class CarGroup {



    constructor(instance_path , instance, instance_n, carspeed) {
        this.instance_path = instance_path;
        this.instance = instance;
        this.instance_n = instance_n;
        this.carspeed = carspeed;
        this.baseFraction = 0;
    }

    updateCars(delta) {
        for (let i = 0; i < this.instance_n; i++) {
        const matrixDummy = new Object3D();
        const localFraction = ((this.instance_n - 1 - i) / this.instance_n) + this.baseFraction;
        const newPosition = this.instance_path.getPoint(localFraction);
        matrixDummy.position.copy(newPosition);
        matrixDummy.lookAt(this.instance_path.getPoint(localFraction + (this.carspeed*delta)));
        matrixDummy.updateMatrix()
        
        this.instance.setMatrixAt(i, matrixDummy.matrix);
        this.instance.instanceMatrix.needsUpdate = true;
        }
        this.baseFraction += this.carspeed*delta;
        if (this.baseFraction > 1 / this.instance_n) {
        this.baseFraction = 0;
        }
    }
}