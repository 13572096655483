//Designed and developed by Barbagency.com
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { HalfFloatType } from 'three'
import { EffectComposer, RenderPass, EffectPass, TiltShiftEffect} from 'postprocessing'
import { gsap } from 'gsap'
import { CarGroup } from './cargroup'
import { ThreePerf } from 'three-perf'

let canMove=true
let landed=false
let local=false

console.log(`%c

^~~~~~.         ~.      ^~~~~~^.   .~~~~~~.     
^&&&&&&#!       5@P     .#@&&&&&#5^ ~@&&&&&#^    
^&@&&&&@G^     5@@@P    .#@&&&&@@@&^~@&&&&&@P:   
^&@&&&&&@&Y   Y@&&&@5   .#@&&&&&&&@7~@&&&&&&@&?  
^&@&&&&&&@@~ Y@&&&&&@5  .#@&&&&&@@P ~@&&&&&&&@&: 
^&@&&&&@@@B:J@@&&&&&@@Y .#@&&&&@B^  !@&&&&&@@@G. 
^&&&&&&&BY.7@&&&&&&&&&@J.#@&&&&&&5. ~@&&&&&&BJ.  
^~~~~~^.  :~~~~~~~~~~~^ ^~~~~~~~~. .~~~~~~:     

Designed and developed by https://barbagency.com


`,'color: #09a0b1')

/**
 * Base
 */

const canvas = document.querySelector('canvas.webgl')
const loaderVal = document.querySelector('#LoaderVal .elementor-widget-container h2')
const instructions = document.getElementById("inst-window")
//tab containers
const contLoad = document.getElementById("load-window")
const contHM = document.getElementById("hm-window")
const contRMN = document.getElementById("rmn-window")
const contDest = document.getElementById("dest-window")
const contOutD = document.getElementById("out-window")
const contMetro = document.getElementById("metro-window")

const containers = [contHM,contRMN,contDest,contOutD,contMetro]
//make them all hidden initially

containers.forEach(container => {if(container){container.style.display="none"}})

let currentContainer=null

//close buttons
const closeHM = document.getElementById("hm-close")
const closeRMN = document.getElementById("rmn-close")
const closeDest = document.getElementById("dest-close")
const closeOutD = document.getElementById("out-close")
const closeMetro = document.getElementById("metro-close")
const closeButtons = [closeHM,closeRMN,closeDest,closeOutD,closeMetro]

//important tab buttons

const rmnMalls = document.getElementById("rmn-malls")
const rmnStore = document.getElementById("rmn-store")
const destXpo = document.getElementById("dest-expo")
const destPalm = document.getElementById("dest-palm")
const destComm = document.getElementById("dest-nakheel")


// Scene
const scene = new THREE.Scene()

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    halfX: window.innerWidth/2,
    height:  window.innerHeight,
    halfY: window.innerHeight/2,
    aspect:  window.innerWidth/window.innerHeight,
    oldAspect:  window.innerWidth/window.innerHeight
}

/**
 * Camera
 */
let isMobile
let rotateSpeed, pan, xLimit
let buttonScale, buttonDelta
let focusArea,blurOffset
let imgQual
let camparam={d:4.75,xOffset:0,yOffset:0}

//defaults
isMobile=false
imgQual = "H/"
rotateSpeed=0.05
buttonScale=0.3
buttonDelta=0
focusArea=0.95
blurOffset=-0.15
pan=false


if(/Android|webOS|BlackBerry|iPhone|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    isMobile = true
    imgQual="L/"
    camparam.d=11
    rotateSpeed=0.15
    buttonScale=.5
    buttonDelta=0.75
    pan=true
    xLimit=7

}
if(/iPad/i.test(navigator.userAgent)){
    //ipad edge case
    camparam.d=5.5
    rotateSpeed=0.1
}


const camera = new THREE.OrthographicCamera(-camparam.d * sizes.aspect, camparam.d * sizes.aspect, camparam.d, -camparam.d, -2*camparam.d, 5*camparam.d)
camera.setViewOffset(sizes.width,sizes.height,camparam.xOffset,camparam.yOffset,sizes.width,sizes.height)






if(isMobile){
    camera.position.set(camparam.d,6+(0.75*camparam.d),-camparam.d)
}else{
    camera.position.set(camparam.d,2+(0.75*camparam.d),-camparam.d)
}

camera.lookAt( scene.position)
scene.add(camera)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias:true,
    powerPreference: 'high-performance'
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputColorSpace = THREE.LinearSRGBColorSpace

// Controls
let controls, root
if(local){
    controls = new OrbitControls(camera, canvas)
    root = './'

}else{
    controls = new OrbitControls(camera, instructions)
    root = '../wp-content/xp/'
}

const minZoom = .25
const maxZoom = 4
controls.enableDamping = true
controls.enablePan= pan
controls.enableZoom = false
controls.maxAzimuthAngle = 2.75
controls.minAzimuthAngle = 2.25
controls.maxPolarAngle = 1.1
controls.minPolarAngle = 0.9
controls.dampingFactor = 0.05
controls.rotateSpeed =rotateSpeed
controls.minZoom=minZoom
controls.maxZoom=maxZoom
controls.enabled=false




// Loaders

const loadingMgr = new THREE.LoadingManager;
const gltfLoader = new GLTFLoader(loadingMgr);
const textureLoader = new THREE.TextureLoader(loadingMgr)
let modelRoot = root+"GLTF/"
let imgRoot = root+"webp/"+imgQual



//UI
let SpriteMat


const uiButtons = []
const btnTl=[]
const Coords = [
    {name: "Metro", label: "Dubai Metro",container: contMetro,btn:null, same: true, x: 5.2, y: 0.75, z:0.8, zoom: 3, zoom2:2},
    {name: "In Store", label: "Retail Media",container: contRMN,btn:rmnStore, same: true, x: 8.2, y: 0.75, z: -1.8, zoom: 2.75, zoom2:1.75},
    {name: "Jumeira", label: "Destinations",container: contDest,btn:destPalm, same: true, x: 3.6, y: 1, z: -2.6, zoom: 2.5,zoom2:1.75},
    {name: "Communities", label: "Destinations",container: contDest,btn:destComm, same: true, x:0, y:1, z:2.4, zoom: 3.5,zoom2:2},
    {name: "Expo City",label: "Destinations",container: contDest,btn:destXpo, same: true, x:-3.2, y:0.85, z:1.6, zoom: 3.5,zoom2:2},
    {name: "HM",label: "Hypermedia HQ",container: contHM, btn:null, same:false,x:0.7, y:1.3, z:-1.6, a: 0.8, b: -1.6, zoom: 3,zoom2:2},
    {name: "In Mall",label: "Retail Media",container: contRMN,btn: rmnMalls, same: true, x:2.0, y:1, z:0.8, a: 2, b: 0.8, zoom: 3,zoom2:2},
    {name: "Outdoor",label: "Outdoor",container:contOutD,btn:null, same: true, x:-4.0, y:0.85, z:-0.4, a: -1.8, b: -0.4, zoom: 4,zoom2:2.5}
]

let currentScene = "Main"

const circleMat = textureLoader.load(imgRoot+'circle.webp')
const circleColor = textureLoader.load(imgRoot+'circle_color.webp')

SpriteMat = new THREE.SpriteMaterial({
    map: circleColor,
    transparent:true,
    opacity:1,
    alphaMap:circleMat
})

for (let i = 0; i<Coords.length;i++){
    const newButton = new THREE.Sprite(SpriteMat)
    newButton.position.set(Coords[i].x,Coords[i].y+buttonDelta,Coords[i].z)
    newButton.scale.set(buttonScale,buttonScale,1)
    newButton.name = Coords[i].name
    newButton.matrixAutoUpdate=true
    newButton.renderOrder=1
    uiButtons.push(newButton)
    scene.add(newButton)
}

//Lights

const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
ambientLight.castShadow = false
scene.add(ambientLight)

const directionnal = new THREE.DirectionalLight(0xffffff,.75)
directionnal.castShadow = false
scene.add(directionnal)



const looped =26
const mytotal =29

loadingMgr.onProgress = function (url, loaded,total) {
    const currentVal = (loaded/total)*100
    loaderVal.innerHTML = currentVal.toFixed(1)+"%"
}

var animated = false
const mixers = [];

// Material
const carMat = new THREE.MeshPhongMaterial({color: 0xcccccc})


//Numbered models
let modelsDone =0
for( let i=1; i <= looped; i++ ){
    gltfLoader.load(modelRoot+i+".gltf",function(gltf){
        gltf.scene.traverse((o) => {
            if (o.isMesh){
                o.castShadow = false
                o.receiveShadow = false
                textureLoader.load(imgRoot+i+".webp",function(texture){
                    const newMaterial = new THREE.MeshBasicMaterial({
                        map:texture,
                        side: THREE.DoubleSide
                    })
                    texture.flipY = false
                    texture.minFilter = THREE.NearestMipMapNearestFilter
                    texture.TextureEncoding = THREE.LinearSRGBColorSpace
                    renderer.initTexture(texture)
                    o.material=newMaterial
                })
            }
          });

        

        if(gltf.animations.length > 0){
            const mixer = new THREE.AnimationMixer(gltf.scene) 
            mixer.clipAction(gltf.animations[0]).play();
            mixers.push(mixer)
            animated= true
        }
 
        scene.add(gltf.scene)

        tickIfDone()
    })
}


//water
const waterColor = textureLoader.load(imgRoot+'water_color.webp')
waterColor.flipY = false
const waterAlpha = textureLoader.load(imgRoot+'water_opacity.webp')
waterAlpha.flipY = false

const waterMat= new THREE.MeshPhysicalMaterial({
    roughness:0,
    transparent:true,
    map: waterColor,
    alphaMap: waterAlpha
})

gltfLoader.load(modelRoot+"water.gltf",function(gltf){
    gltf.scene.traverse((o) => {
        if (o.isMesh){
           o.material = waterMat
        }
      });
    gltf.scene.renderOrder=0
    scene.add(gltf.scene)
    tickIfDone()
})


//tree clones
const treeColor = textureLoader.load(imgRoot+'tree_color.webp')
treeColor.flipY = false
const treeAlpha = textureLoader.load(imgRoot+'tree_alpha.webp')
treeAlpha.flipY = false
const treeMat = new THREE.MeshLambertMaterial({
    transparent:true,
    depthTest:true,
    depthWrite:true,
    map: treeColor,
    alphaMap: treeAlpha
})

gltfLoader.load(modelRoot+"trees.gltf",function(gltf){
    const treeGeo = gltf.scene.getObjectByName("Palm_Mesh").geometry;
    const clones = gltf.scene.getObjectByName("Clones").children;
    


    const trees = new THREE.InstancedMesh(treeGeo,treeMat,clones.length)
    trees.castShadow = true
    trees.receiveShadow = true
    trees.instanceMatrix.needsUpdate = true
    const dummyObject = new THREE.Object3D()

    for(let i=0;i<clones.length;i++){        
        dummyObject.position.set(clones[i].position.x,clones[i].position.y,clones[i].position.z)
        const randAngle = (Math.random()*360)*Math.PI/180
        dummyObject.rotateY(randAngle)
        dummyObject.updateMatrix()


        trees.setMatrixAt(i,dummyObject.matrix)        
    }

    scene.add(trees)
    tickIfDone()
})
//tree clones end

//SZR cars
async function loadPoints() {
    const response = await fetch(root+'internal.json');
    const splineData = await response.json();
    return splineData.points;
  }

let points01,curve01
let internalCars, internalCarGroup
let Line1Cars, Line1CarGroup
let Line2Cars, Line2CarGroup
let Line3Cars, Line3CarGroup
let Line4Cars, Line4CarGroup
let AnimatedCars = []

loadPoints().then(pointsData => {
    points01 = pointsData.map(point => new THREE.Vector3(point[0],0,point[1]));
    curve01 = new THREE.CatmullRomCurve3(points01);

    curve01.curveType = 'centripetal'
    curve01.tension = 0
    curve01.closed = true

    const p1=new THREE.Vector3(-14.2,0,-0.235)
    const p2=new THREE.Vector3(14.2,0,-0.235)
    const p3=new THREE.Vector3(-14.2,0,-0.365)
    const p4=new THREE.Vector3(14.2,0,-0.365)
    const p5=new THREE.Vector3(14.2,0,-0.545)
    const p6=new THREE.Vector3(-14.2,0,-0.545)
    const p7=new THREE.Vector3(14.2,0,-0.675)
    const p8=new THREE.Vector3(-14.2,0,-0.675)

    const line1= new THREE.LineCurve3(p1,p2)
    const line2 = new THREE.LineCurve3(p3,p4)
    const line3 = new THREE.LineCurve3(p5,p6)
    const line4 = new THREE.LineCurve3(p7,p8)


    const internalCarsNum = 20;
    const internal_speed = 0.005

    const szrLane = 10;
    const szr_speed = 0.015


    gltfLoader.load(modelRoot+'car.gltf',function(gltf){
        const carGeo = gltf.scene.getObjectByName("Car_Mesh").geometry;
        carGeo.rotateY(Math.PI)

        internalCars = new THREE.InstancedMesh(carGeo,carMat,internalCarsNum)
        internalCarGroup = new CarGroup(curve01,internalCars,internalCarsNum,internal_speed)

        Line1Cars = new THREE.InstancedMesh(carGeo,carMat,szrLane-5)
        Line1CarGroup = new CarGroup(line1,Line1Cars,szrLane-5,szr_speed-0.005)

        Line2Cars = new THREE.InstancedMesh(carGeo,carMat,szrLane)
        Line2CarGroup = new CarGroup(line2,Line2Cars,szrLane,szr_speed)

        Line3Cars = new THREE.InstancedMesh(carGeo,carMat,szrLane)
        Line3CarGroup = new CarGroup(line3,Line3Cars,szrLane,szr_speed)

        Line4Cars = new THREE.InstancedMesh(carGeo,carMat,szrLane-6)
        Line4CarGroup = new CarGroup(line4,Line4Cars,szrLane-6,szr_speed-0.004)

        //szr
        internalCars.castShadow = false
        Line1Cars.castShadow = false
        Line2Cars.castShadow = false
        Line3Cars.castShadow = false
        Line4Cars.castShadow = false

        AnimatedCars.push(internalCars,Line1Cars,Line2Cars,Line3Cars,Line4Cars)
        scene.add(internalCars, Line1Cars, Line2Cars, Line3Cars, Line4Cars)

        //parking cars
        const clones = gltf.scene.getObjectByName("Clones").children;
        const parkingCars = new THREE.InstancedMesh(carGeo,carMat,clones.length)
        const dummyObject = new THREE.Object3D()

        for(let i=0;i<clones.length;i++){        
            dummyObject.position.set(clones[i].position.x,clones[i].position.y,clones[i].position.z)
            dummyObject.scale.set(0.85,0.85,0.85)
            dummyObject.rotateY(Math.round(Math.random())*Math.PI)
            dummyObject.updateMatrix()


            parkingCars.setMatrixAt(i,dummyObject.matrix)
            parkingCars.updateMatrix()
        }

        scene.add(parkingCars)
        tickIfDone()
    })
});

//SZR cars end


//-------------------------------------Event Listeners Start-----------------------------------------//

window.addEventListener('resize', () =>
{
    doUpdates()
})

window.addEventListener("keyup",(e)=>{
    if(e.code == "Escape"){
        backToStart()
    }
    })

//close container events


//Internal tab click events
window.onload=(event)=>{
    rmnMalls.addEventListener("click",()=>{changescene("In Mall")})
    rmnStore.addEventListener("click",()=>{changescene("In Store")})
    destXpo.addEventListener("click",()=>{changescene("Expo City")})
    destPalm.addEventListener("click",()=>{changescene("Jumeira")})
    destComm.addEventListener("click",()=>{changescene("Communities")})

    closeButtons.forEach(button =>{ if(button){button.addEventListener("click",()=>{backToStart()})}})
}


const raycaster = new THREE.Raycaster()
const mouse = new THREE.Vector2()
let intersects = []

//Map button click listener
window.addEventListener('click', (e) => {
    mouse.set((e.clientX / sizes.width) * 2 - 1, -(e.clientY / sizes.height) * 2 + 1)
    raycaster.setFromCamera(mouse, camera)
    intersects = raycaster.intersectObjects(uiButtons, true)
    
    if(intersects.length>0){
        const firstHit = intersects[0].object;

        if(firstHit.name != currentScene && canMove){
            //scale up and down
            gsap.to(firstHit.scale,{x:1.15*buttonScale,y:1.15*buttonScale,duration:0.1,onComplete:()=>{
                gsap.to(firstHit.scale,{x:buttonScale,y:buttonScale,duration:0.3})
            }})

            canMove=false
            changescene(firstHit.name)
        }
    }
})


function findTimeline(btnTl,searchString){
    for(let i=0; i<btnTl.length;i++){
        if(btnTl[i][0] === searchString){
            return i;
        }
    }
}
let paused
let currentHover
//Map button hover
window.addEventListener('mousemove', (e) => {
    if(landed && currentScene=="Main"){
        mouse.set((e.clientX / sizes.width) * 2 - 1, -(e.clientY / sizes.height) * 2 + 1)
        raycaster.setFromCamera(mouse, camera)
        intersects = raycaster.intersectObjects(uiButtons, true)
        //console.log(intersects)

        if(intersects.length>0){
            document.body.style.cursor= 'pointer'
            const firstHit = intersects[0].object;
            paused = findTimeline(btnTl,firstHit.name)
            btnTl[paused][1].pause()
            //scale up and down        
            if(currentHover !== firstHit.name)                      
                gsap.to(firstHit.scale,{x:1.3*buttonScale,y:1.3*buttonScale,duration:0.1,onComplete:()=>{
                    currentHover = firstHit.name
                    gsap.to(firstHit.scale,{x:buttonScale,y:buttonScale,duration:0.3})
                }})
        }else{
            document.body.style.cursor='default'
            currentHover=""
            if(paused>=0){
                btnTl[paused][1].play()
                paused = -1
            }
            
            
        }
    }
})

window.addEventListener('touchstart',function(event){
    if (event.touches.length>1 && isMobile && currentScene === "Main"){
        controls.enableDamping=false
        controls.enableRotate=false
    }
})

window.addEventListener('touchmove',function(event){
    if (event.touches.length>1 && isMobile && currentScene === "Main"){
        controls.target.z=0
        controls.target.y=0
        if(isMobile){

        }
        if(controls.target.x>xLimit){
            controls.target.x=xLimit
        }
        if(controls.target.x<-xLimit){
            controls.target.x = -xLimit
        }
    }
})

window.addEventListener('touchend',function(event){
    if(isMobile && currentScene==="Main" && !controls.enableDamping){
        controls.enableDamping=true
        controls.enableRotate=true
    }

})

//-------------------------------------Event Listeners End------------------------------------------//


//camera motion
const dur = 2

//hide loading screen div and animate cameras in
loadingMgr.onLoad = function(){
    gsap.to(contLoad,{opacity:0,duration:0.75,ease: "power1.inOut",
    onComplete:()=>{
     contLoad.style.display = "none"
    }})
    if(isMobile){
        gsap.to(camparam,{d:5.5,delay:3,duration:5,ease:"power1.inOut",onUpdate:updateRenderSizes,onComplete:()=>{
            controls.enabled=true
        }})
    }
    gsap.to(camera.position,{delay:0.5,ease:"power2.out", x:camparam.d,y:0.75*camparam.d,z:-camparam.d,duration:2,onComplete:()=>{
        landed=true
        controls.enabled=true
    }})


    uiButtons.forEach(button =>{
        const newpos = (Math.random()*2)+3+ button.position.y
        gsap.from(button.position,{y: newpos,ease:"power2.out",duration:2, onComplete:()=>{
            var tl = gsap.timeline({repeat:-1,yoyo:true})
            tl.to(button.position,{
                y:button.position.y+0.2,
                delay: ((Math.random()/2)+0.25)*1.5,
                duration:2,                
                ease: "sine.inOut"
            })

            btnTl.push([button.name,tl])

        }})
    })
}

function backToStart(){
    //reset camera position and target
    controls.enabled=false
    gsap.to(camera.position,{x:4.75,y:3.5625,z:-4.75, duration:dur,ease: "power1.inOut",onComplete:()=>{
        controls.enabled=true
        if(isMobile){
            controls.enablePan=true
        }
    }})
    gsap.to(controls.target,{x:0,y:0,z:0,duration:dur,ease: "power1.inOut"})    
    //reset camera zoom and xOffset
    gsap.to(camparam,{xOffset:0,yOffset:0,duration:dur,ease: "power1.inOut"})
    gsap.to(camera,{zoom:1, duration:dur,ease: "power1.inOut", onUpdate:updateRenderSizes})
    

    //reset ui opacity
    gsap.to(SpriteMat,{opacity:1,duration:dur/2,ease: "power1.inOut"})
    //move current container out

    if(isMobile){
        if(currentContainer){
            gsap.to(currentContainer,{opacity:0,top:'100vh',duration:dur/2,ease: "power1.inOut",onComplete:()=>{
                currentContainer.style.display="none"
                currentContainer.style.opacity=1
                currentContainer.style.top=0
                currentContainer = null
            }})
        }
    }else{
        if(currentContainer){
            gsap.to(currentContainer,{opacity:0,right:'-50vw',duration:dur/2,ease: "power1.inOut",onComplete:()=>{
                currentContainer.style.display="none"
                currentContainer.style.opacity=1
                currentContainer.style.right=0
                currentContainer = null
            }})
        }
    }

    currentScene="Main"
    canMove=true
}

function changescene(newlocation){
    controls.enabled=false
    currentScene = newlocation
    document.body.style.cursor= 'cursor'
    // find location in array
    let targetIndex = Coords.findIndex((index)=> index.name === newlocation)

    const newTarget = Coords[targetIndex]
    let xComponent, zComponent
    if(newTarget.same){
        xComponent = newTarget.x
        zComponent = newTarget.z
    }else{
        xComponent = newTarget.a
        zComponent = newTarget.b
    }
    // animate camera and target
    gsap.to(camera.position,{x:xComponent+2.5,y: 1.875,z:zComponent-2.5, duration:dur,ease: "power1.inOut",onComplete:()=>{
        controls.enabled=true
        controls.enablePan=false
    }})
    gsap.to(controls.target,{x:xComponent,y:0,z:zComponent,duration:dur,ease: "power1.inOut"})
    //change camere zoom and xOffset
    
    if(!isMobile){
        gsap.to(camparam,{xOffset:sizes.width/4,duration:dur,ease: "power1.inOut"})
        gsap.to(camera,{zoom:newTarget.zoom, duration:dur,ease: "power1.inOut", onUpdate:updateRenderSizes})
    }else{
        gsap.to(camparam,{yOffset:sizes.height/4,duration:dur,ease: "power1.inOut"})
        gsap.to(camera,{zoom:newTarget.zoom2, duration:dur,ease: "power1.inOut", onUpdate:updateRenderSizes})
    }
    //fade out buttons
    gsap.to(SpriteMat,{opacity:0,duration:dur/2,ease: "power1.inOut"})

    //if the new container if different, check if you need to click a specific one    
    if(newTarget.container !== currentContainer){
        if(newTarget.btn != null){
            newTarget.btn.click()
        }
        //enable the new container
        newTarget.container.style.display="initial"
        currentContainer = newTarget.container
    }

}

//updates

function updateSizesParams(){
    sizes.oldAspect = sizes.aspect
    sizes.width = window.innerWidth
    sizes.halfX = window.innerWidth/2
    sizes.height = window.innerHeight
    sizes.halfY = window.innerHeight/2    
    sizes.aspect = sizes.width/sizes.height
}

function updateRenderSizes(){
    camera.left = -camparam.d*sizes.aspect
    camera.right = camparam.d*sizes.aspect
    camera.top = camparam.d
    camera.bottom = -camparam.d
    camera.aspect = sizes.aspect
    camera.setViewOffset(sizes.width,sizes.height,camparam.xOffset,camparam.yOffset,sizes.width,sizes.height)
    composer.setSize(sizes.width,sizes.height)

    camera.updateProjectionMatrix()
}

function doUpdates(){
    updateSizesParams()
    updateRenderSizes()
}


//post processing

const composer = new EffectComposer(renderer,{frameBufferType: HalfFloatType});

const tilt = new TiltShiftEffect({
    offset:blurOffset,
    resolutionX: sizes.width,
    resolutionY: sizes.height,
    feather: 0.25,
    focusArea: focusArea

})

composer.addPass(new RenderPass(scene, camera));

if(!isMobile){
    composer.addPass(new EffectPass(camera, tilt));
}


//Animate
const clock = new THREE.Clock()

const tick = () =>
{
    const delta = clock.getDelta()

    // Update controls
    controls.update()

    if(curve01 && internalCars){
        internalCarGroup.updateCars(delta)
        Line1CarGroup.updateCars(delta)
        Line2CarGroup.updateCars(delta)
        Line3CarGroup.updateCars(delta)
        Line4CarGroup.updateCars(delta)
    }

    if(animated){
        for(const mixer of mixers) mixer.update( delta );
    }

    //render
    composer.render(scene,camera)
 
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

function tickIfDone() {
    modelsDone += 1
    if (modelsDone >= mytotal) {
        tick()
    }
}
